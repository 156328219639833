.row .module .top-five {
	font-size: 1.4em;
	line-height: 2em;
}

.row .module .overview {
	color: #888;
	font-size: 1.3em;
	clear: both;
}

.row .module .overview a {
	color: #888;
}

.row .module .overview .text {
	float: left;
	margin-left: 25px;
	margin-top: 2px;
	margin-bottom: 15px;
}

.row .module .overview .icon {
	float: left;
	width: 30px;
}