.weekdays {
	font-size: 2em;
	margin-right: 20px;
}

.title-date {
	text-align: center;
	font-size: 1.5em;
}

.title-date .prev {
	font-weight: bold;
	margin-right: 30px;
	cursor: pointer;
}

.title-date .next {
	font-weight: bold;
	margin-left: 30px;
	cursor: pointer;
}

.calendar thead tr td {
	border: none;
	font-weight: bold;
	color: #AFAFAF;
	padding: 0;
	font-size: 1.3em;
}

.calendar tbody td {
	width: 14.2857142857%;
	height: 120px;
	vertical-align: top;
	padding: 5px;
	border: 1px solid #F2F2F2;
}

.calendar tbody td .date {
	font-weight: bold;
}

.calendar tbody td .animation {
	border-radius: 6px;
	background-color: #888;
	font-weight: bold;
	color: white;
	padding: 5px 10px;
	font-size: 0.8em;
}

.calendar tbody td .delete:hover {
	text-decoration: underline;
	cursor: pointer;
}