/* MAIN.CC */

body {
	margin: 0;
	padding: 0;
}

h1 {
	font-size: 1.5em;
}

.error {
	color: red;
}

.success {
	color: green;
}

.bigger {
	font-size: 1.8em;
}

.underline {
	text-decoration: underline;
}

.uhover {
	text-decoration: none;
}

.uhover:hover {
	text-decoration: underline;
	cursor: pointer;
}

.avatar {
	height: 60px;
	width: 60px;
	border-radius: 50%;
	padding-top: 0;
}

.wrapper {
	margin-top: 30px;
	margin-left: 35px;
}

.wrapper-content > p {
	text-align: left;
}

.wrapper-content > .center {
	text-align: center;
	margin-left: 0;
}

.wrapper-content h1, .wrapper-content h2, .wrapper-content h3, .wrapper-content h4, .wrapper-content h5, .wrapper-content h6 {
	margin-top: 0;
}

.alink {
	color: #4242dd;
}

.alink:hover {
	text-decoration: underline;
	cursor: pointer;
}

.link {
	cursor: pointer;
}

a:hover, .link:hover {
	text-decoration: none;
}

.button-text {
	cursor: pointer;
}

.button-text span {
	margin-left: 10px;
}

/* FORMS */

label, .label {
	display: block;
	margin: 20px 0;
}

/* --- INPUT DEGUEU COMMENTÉ POUR SE LOGUER COTE DASHBOARD --- */
/* input, select {
	background-color: white;
	box-shadow: #424242;
	border: 1px solid #acacac;
	margin: 10px 30px;
	min-width: 200px;
	height: 25px;
} */
/* --- INPUT DEGUEU COMMENTÉ POUR SE LOGUER COTE DASHBOARD --- */

/* TABLE */

/*
table {
	width: 90%;
	border-collapse: collapse;
	margin: 20px auto;
}

table thead {
	font-weight: bold;
	text-align: center;
}

table td {
	border: 1px solid #424242;
}
*/

/* MODULE */

.row .module a {
	color: #4242DD;
	text-decoration: none;
}

.row .module a:hover {
	text-decoration: underline;
}

.row .module a.btn {
	color: white;
	text-decoration: none;
}

.row .module img {
	max-width: 100%;
}

.row .module .icon {
	max-width: 100px;
}

.row .module .icon.blank {
	background-color: #273044;
}

.row .module .small {
	width: 50px;
}

.row .module .module-form {
	width: 100%;
	margin: auto;
	margin-top: 20px;
}

.row .module .module-form .prev {
	width: 400px;
}

.row .module .module-form .info {
	font-size: 0.9em;
	color: #929292;
}

.row .module .module-form .title-group {
	color: #AFAFAF;
	padding: 0;
	margin: 0 0 0 15px;
	font-size: 1.2em;
}

.row .module .module-form .form-group {
	clear: both;
	width: 100%;
	border: 1px solid #DADADA;
	border-radius: 10px;
	padding: 20px 40px;
	margin: 0 0 50px 0;
}

.row .module .module-form label {
	clear: both;
	margin: 0;
	padding: 0;
	color: black;
}

.row .module .module-form .short-label {
	width: 50%;
}

.row .module .module-form .short-label input[type="text"] {
	height: 1.8em;
	width: auto;
}

.row .module .module-form input, .row .module .module-form select {
	min-width: 0;
}

.row .module .module-form p.column-5,
.row .module .module-form p.column-10,
.row .module .module-form p.column-20,
.row .module .module-form p.column-30,
.row .module .module-form p.column-40,
.row .module .module-form p.column-50,
.row .module .module-form p.column-60,
.row .module .module-form p.column-70,
.row .module .module-form p.column-80,
.row .module .module-form p.column-90 {
	font-size: 1.3em;
}

.row .module .module-form .column-5,
.row .module .module-form .column-10,
.row .module .module-form .column-20,
.row .module .module-form .column-30,
.row .module .module-form .column-40,
.row .module .module-form .column-50,
.row .module .module-form .column-60,
.row .module .module-form .column-70,
.row .module .module-form .column-80,
.row .module .module-form .column-90 {
	float: left;
	padding: 0;
}

.row .module .module-form .column-5.right60,
.row .module .module-form .column-10.right60,
.row .module .module-form .column-20.right60,
.row .module .module-form .column-30.right60,
.row .module .module-form .column-40.right60,
.row .module .module-form .column-50.right60,
.row .module .module-form .column-60.right60,
.row .module .module-form .column-70.right60,
.row .module .module-form .column-80.right60,
.row .module .module-form .column-90.right60 {
	margin-right: 60px;
}

.row .module .module-form .column-5 {
	width: 5%;
}

.row .module .module-form .column-10 {
	width: 10%;
}

.row .module .module-form .column-20 {
	width: 20%;
}

.row .module .module-form .column-30 {
	width: 30%;
}

.row .module .module-form .column-40 {
	width: 40%;
}

.row .module .module-form .column-50 {
	width: 50%;
}

.row .module .module-form .column-60 {
	width: 60%;
}

.row .module .module-form .column-70 {
	width: 70%;
}

.row .module .module-form .column-80 {
	width: 80%;
}

.row .module .module-form .column-90 {
	width: 90%;
}


.row .module .module-form .sep {
	float: left;
	padding: 0 10px;
	height: 3rem;
	line-height: 3rem;
	margin: 1px 0 8px 10px;
	border-bottom: 1px solid #9E9E9E;
	font-size: 16px;
}

.row .module .module-form .sep p {
	margin: 0;
	padding: 0;
}

.row .module .module-form input[type="text"] {
	padding: 0 10px;
}

.row .module .module-form input[type="file"] {
	border: none;
}

.row .module .module-form fieldset {
	margin-top: 20px;
	border: 1px solid black;
	padding: 10px 45px 10px 25px;
}

.row .module .module-form fieldset legend {
	text-align: center;
	font-weight: bold;
	font-size: 1.4em;
}

.wrapper-content .module {
	background-color: white;
	margin-top: 40px;
	width: 100%;
	border-radius: 4px;
	text-align: left;
	padding: 30px 40px;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.wrapper-content .module.transparent {
	background-color: none;
	box-shadow: none;
}

.module .btn-small.margin-left {
	margin-left: 10px;
}

.module .object {
	float: left;
	width: 30%;
	margin-top: 50px;
	margin-right: 3%;
	min-height: 100px;
	margin: auto;
	background-color: #F2F2F2;
	border-radius: 10px;
	margin: 20px 1.5%;
	box-shadow: 0px 3px 6px #00000029;
}

.module .object.full-width {
	float: none;
	width: 100%;
	margin-right: 0;
}

.module .object .pastille {
	border-radius: 100%;
	width: 60px;
	height: 60px;
	display: block;
	float: left;
	background-color: #888;
}

.module .object .pastille img {
	width: 80%;
	margin: 4px auto;
	display: block;
}

.module .object .title {
	padding: 50px 0;
	font-size: 1.3em;
	margin-left: 5%;
	width: 45%;
	float: left;
}

.module .object .title .icon {
	background: #273044;
	float: left;
	width: 60px;
	height: 60px;
}

.module .object .title .icon.fill {
	background: none;
}

.module .object .title .icon img {
	width: 50px;
	height: 50px;
	margin-left: 5px;
	margin-top: 5px;
}

.module .object .title p {
	padding: 0;
	margin: 0;
	margin-left: 80px;
	margin-top: 10px;
}

.module .object .title p:hover {
	text-decoration: underline;
	cursor: pointer;
}

.module .object .buttons {
	padding: 50px 0;
	width: 45%;
	margin-right: 5%;
	text-align: right;
	float: right;
}

.module .object .buttons button {
	margin: 10px;
}

.module .object .clear {
	clear: both;
}

.module .half {
	width: 50%;
	float: left;
}

.module .object-2 {
	width: 100%;
	min-height: 100px;
	margin: auto;
	background-color: #F2F2F2;
	border-radius: 10px;
	margin: 40px 0;
	padding: 20px 40px;
	font-size: 1.2em;
}

.module .object-2 .client_number {
	float: right;
	font-size: 2em;
	margin-bottom: 20px;
}

.module .object-2 .activation_code {
	float: left;
	font-size: 2em;
	margin-bottom: 20px;
}

.module .object-2 .infos {
	clear: both;
	width: 100%;
	background-color: white;
}

.module .object-2 .infos td {
	width: calc(100% / 7);
}

.module .object-2 .infos .green {
	background-color: green;
}

.module .object-2 .infos .red {
	background-color: red;
}

.module .object-2 .infos .notification {
	width: 50%;
}

.module .object-2 .subtitle {
	margin-top: 40px;
	margin-left: 80px;
	font-weight: bold;
	font-size: 1.5em;
}

/* vignettes */

.module .vignette {
	width: 17%;
	background-color: white;
	border-radius: 10px;
	height: 200px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.29);
	position: relative;
	float: left;
	margin: 20px 1.5%;
}

.module .vignette .title {
	text-align: center;
	padding-top: 60px;
	font-size: 1.2em;
	cursor: pointer;
}

.module .vignette .title a {
	color: rgba(0,0,0,0.87);
	text-decoration: none;
}

.module .vignette .title a:hover {
	text-decoration: none;
}

.module .vignette .sep {
	border-top: 2px solid #F3F3F3;
	position: absolute;
	width: 100%;
	top: 150px;
	text-align: right;
	padding-top: 5px;
}

.module .vignette .sep .more {
	margin-right: 15px;
}

.module .vignette .sep i {
	cursor: pointer;
}

.module .vignette .sep .menu {
	position: absolute;
	top: 0 !important;
	left: 80% !important;
	width: 60% !important;
	text-align: left;
	background-color: white;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.29);
}

.module .vignette .sep .menu li {
	padding: 15px 20px 10px 20px;
	cursor: pointer;
}

.module .vignette .sep .menu li.delete {
	color: red;
}

.module .vignette .sep .menu li.sep-ul {
	border-top: 2px solid #F3F3F3;
}

.module .pages-nb {
	float: right;
}

.module .pages-nb p {
	float: left;
	color: white;
	font-size: 1.2em;
	padding: 10px;
	margin: 5px;
	width: 3em;
	cursor: pointer;
	text-align: center;
	background-color: #26A69A;
	border: 1px solid #26A69A;
}

.module .pages-nb p:hover {
	background-color: white;
	color: #26A69A;
}

.module .clients .user-checkbox span {
	font-size: 1.5em;
	color: black;
	margin-left: 0;
	float: left;
}

.module .clients .user-checkbox label span {
	margin-left: 20px;
	margin-top: 7px;
}

.module .select-buttons {
	margin-bottom: 20px;
}

.loader {
	margin: auto;
	margin-top: 40vh;
	text-align: center;
}

.loader-image {
	margin: auto;
	width: 300px;
}

.reviews td {
	text-align: center;
	min-width: 150px;
}

.reviews thead {
	font-weight: bold;
}

.notation {
	text-align: center;
}

.notation img {
	width: 15px;
	margin: 0 2px;
}