.icon-picker {
	border: 1px solid black;
	position: relative;
	margin-top: 10px;
/*	margin-top: -100px;
	margin-left: 100px;
*/	width: 400px;
	background-color: white;
	z-index: 1000;
}

.icons-table {
	text-align: center;
}

.icons-table td {
	padding: 10px;
	cursor: pointer;
}

.icons-table .blank {
	background-color: #273044;
}

.icons-table img {
	width: 50px;
}

.icons-table.mini {
	width: 50%;
}

.icons-table.mini td {
	padding: 0;
}

.icons-table.mini img {
	width: 30px;
}