/* .booking thead td {
	text-align: center;
	font-size: large;
} */


.booking-wrapper {
	max-height: 700px;
	overflow-y: scroll;
}

.booking .hours {
	width: 10%;
	float: left;
	border-right: 1px solid #E0E0E0;
	font-weight: bold;
	border-bottom: 1px solid #E0E0E0;
}

.booking .workers-cols {
	float: left;
	width: 90%;
	border-bottom: 1px solid #E0E0E0;
}

.booking .booking-row {
	clear: both;
}

.booking .booking-hours {
	width: 100%;
	float: left;
	height: 180px;
	padding-top: 80px;
	border-top: 1px solid #E0E0E0;
}

.booking .booking-col {
	height: 30px;
	float: left;
	border-top: 1px solid #E0E0E0;
	border-right: 1px solid #E0E0E0;
}

.booking .booking-col.hover {
	background-color: #EBF5FD;
	color: #47A0FD;
	padding-top: 4px;
	padding-left: 5px;
}

.booking .booking-col .reservation {
	line-height: 1.5em;
	color: black;
	float: left;
	margin-left: 4px;
	position: relative;
	background-color: #A5DFF8;
	border-radius: 5px;
	margin-top: -15px;
	padding: 0 15px;
	z-index: 20;
}

.booking .booking-col .reservation p {
	position: relative;
}

.booking-header {
	width: 100%;
}

.booking-header .first {
	margin-left: 10%;
}

.booking-header .worker-name {
	text-align: center;
	font-size: 1.3em;
	float: left;
}

.booking-date {
	text-align: center;
	font-size: 1.5em;
}

.booking-date .prev {
	float: left;
	margin-left: 30%;
	font-weight: bold;
	cursor: pointer;
}

.booking-date .next {
	float: right;
	margin-right: 30%;
	font-weight: bold;
	cursor: pointer;
}

.booking-modal .benefit-title {
	font-size: 1.2em;
	font-weight: bold;
}

.booking-modal .reservation {
	background-color: white;
	border-radius: 5px;
	padding: 5px 15px;
	padding-bottom: 25px;
	width: 45%;
	float: left;
	margin: 0 2%;
	margin-bottom: 20px;
}