body
{
	background-color: #F4F4F4;
	font-size: 14px;
}

h2
{
	font-size: 1.2em;
}
label
{
	color: black;
}

.Main
{
	padding-right: 2.5% !important;
}

b, strong
{
	font-weight: bolder;
}

.loading
{
	margin-top: 45vh;
}

.center
{
	text-align: center;
}

.clear
{
	clear: both;
}

.left
{
	float: left;
}

.right
{
	float: right;
}

.x-large
{
	font-size: 1.5em;
}

.margin-left-10
{
	margin-left: 10px !important;
}

.ck-editor ul
{
	list-style-type: disc;
	padding-left: 40px;
}

.ck-editor ul li
{
	list-style-type: disc;
}

.row .quickview .icon
{
	width: 22%;
	float: right;
	margin: 0;
}

.row .quickview .text
{
	font-size: 1.3em;
	float: left;
	margin: 0;
}

.row .quickview .text .info
{
	font-size: 0.8em;
	color: #888;
}

.row .quickview .text strong
{
	font-weight: bold;
	font-size: 1.1em;
}

.row .module .title-description
{
	color: #777;
	border-bottom: 1px solid #CCC;
	margin: -20px -40px;
	padding-left: 20px;
	padding-top: 5px;
	padding-bottom: 15px;
	margin-bottom: 20px;
	font-weight: bold;
}

.row .module.col.s1, .row .module.col.m1, .row .module.col.l1
{
	width: 6.33333333333%;
	margin-right: 1%;
	margin-left: 1%;
}

.row .module.col.s2, .row .module.col.m2, .row .module.col.l2
{
	width: 14.66666666667%;
	margin-right: 1%;
	margin-left: 1%;
}

.row .module.col.s3, .row .module.col.m3, .row .module.col.l3
{
	width: 23%;
	margin-right: 1%;
	margin-left: 1%;
}

.row .module.col.s4, .row .module.col.m4, .row .module.col.l4
{
	width: 31.33333333333%;
	margin-right: 1%;
	margin-left: 1%;
}

.row .module.col.s5, .row .module.col.m5, .row .module.col.l5
{
	width: 39.66666666667%;
	margin-right: 1%;
	margin-left: 1%;
}

.row .module.col.s6, .row .module.col.m6, .row .module.col.l6
{
	width: 48%;
	margin-right: 1%;
	margin-left: 1%;
}

.row .module.col.s7, .row .module.col.m7, .row .module.col.l7
{
	width: 56.33333333333%;
	margin-right: 1%;
	margin-left: 1%;
}

.row .module.col.s8, .row .module.col.m8, .row .module.col.l8
{
	width: 64.66666666667%;
	margin-right: 1%;
	margin-left: 1%;
}

.row .module.col.s9, .row .module.col.m9, .row .module.col.l9
{
	width: 73%;
	margin-right: 1%;
	margin-left: 1%;
}

.row .module.col.s10, .row .module.col.m10, .row .module.col.l10
{
	width: 83.33333333333%;
	margin-right: 1%;
	margin-left: 1%;
}

.row .module.col.s11, .row .module.col.m11, .row .module.col.l11
{
	width: 91.66666666667%;
	margin-right: 1%;
	margin-left: 1%;
}

.row .module.col.s12, .row .module.col.m12, .row .module.col.l12
{
	width: 98%;
	margin-right: 1%;
	margin-left: 1%;
}

.row .module.col.offset-s1, .row.module.col.offset-m1, .row.module.col.offset-l1
{
	margin-left: 8.33333333333%;
}

.row .module.col.offset-s2, .row.module.col.offset-m2, .row.module.col.offset-l2
{
	margin-left: 16.6666666667%;
}

.row .module.col.offset-s3, .row.module.col.offset-m3, .row.module.col.offset-l3
{
	margin-left: 25%;
}

.row .module.col.offset-s4, .row.module.col.offset-m4, .row.module.col.offset-l4
{
	margin-left: 33.3333333333%;
}

.row .module.col.offset-s5, .row.module.col.offset-m5, .row.module.col.offset-l5
{
	margin-left: 41.6666666667%;
}

.row .module.col.offset-s6, .row.module.col.offset-m6, .row.module.col.offset-l6
{
	margin-left: 50%;
}

.row .module.col.offset-s7, .row.module.col.offset-m7, .row.module.col.offset-l7
{
	margin-left: 58.3333333333%;
}

.row .module.col.offset-s8, .row.module.col.offset-m8, .row.module.col.offset-l8
{
	margin-left: 66.6666666667%;
}

.row .module.col.offset-s9, .row.module.col.offset-m9, .row.module.col.offset-l9
{
	margin-left: 75%;
}

.row .module.col.offset-s10, .row.module.col.offset-m10, .row.module.col.offset-l10
{
	margin-left: 83.3333333333%;
}

.row .module.col.offset-s11, .row.module.col.offset-m11, .row.module.col.offset-l11
{
	margin-left: 91.6666666667%;
}

.row .module.col.offset-s12, .row.module.col.offset-m12, .row.module.col.offset-l12
{
	margin-left: 100%;
}

.row .module.full
{
	width: auto;
}

.form-langs
{
	font-size: 2em;
	margin-right: 20px;
}

.form-langs img
{
	width: 40px;
}

.form-langs-checkbox
{
	margin-left: 10px;
}

.translation-flags
{
	list-style-type: none;
}

.translation-flags li
{
	float: left;
	border: 1px solid #D9D9D9;
	padding: 7px 7px 2px 7px;
	margin-left: -1px;
	cursor: pointer;
}

.translation-flags li.selected
{
	background-color: #E6E6E6;
}

.translation-flags li img
{
	width: 20px;
}