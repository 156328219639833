/* purgecss start ignore */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* purgecss end ignore */

/* purgecss start ignore */

body
{
  background-color: #F4F4F4;
  font-size: 14px;
}

h2
{
  font-size: 1.2em;
}

label
{
  color: black;
}

.Main
{
  padding-right: 2.5% !important;
}

b, strong
{
  font-weight: bolder;
}

.loading
{
  margin-top: 45vh;
}

.center
{
  text-align: center;
}

.clear
{
  clear: both;
}

.left
{
  float: left;
}

.right
{
  float: right;
}

.x-large
{
  font-size: 1.5em;
}

.margin-left-10
{
  margin-left: 10px !important;
}

.ck-editor ul
{
  list-style-type: disc;
  padding-left: 40px;
}

.ck-editor ul li
{
  list-style-type: disc;
}

.row .quickview .icon
{
  width: 22%;
  float: right;
  margin: 0;
}

.row .quickview .text
{
  font-size: 1.3em;
  float: left;
  margin: 0;
}

.row .quickview .text .info
{
  font-size: 0.8em;
  color: #888;
}

.row .quickview .text strong
{
  font-weight: bold;
  font-size: 1.1em;
}

.row .module .title-description
{
  color: #777;
  border-bottom: 1px solid #CCC;
  margin: -20px -40px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  font-weight: bold;
}

.row .module.col.s1, .row .module.col.m1, .row .module.col.l1
{
  width: 6.33333333333%;
  margin-right: 1%;
  margin-left: 1%;
}

.row .module.col.s2, .row .module.col.m2, .row .module.col.l2
{
  width: 14.66666666667%;
  margin-right: 1%;
  margin-left: 1%;
}

.row .module.col.s3, .row .module.col.m3, .row .module.col.l3
{
  width: 23%;
  margin-right: 1%;
  margin-left: 1%;
}

.row .module.col.s4, .row .module.col.m4, .row .module.col.l4
{
  width: 31.33333333333%;
  margin-right: 1%;
  margin-left: 1%;
}

.row .module.col.s5, .row .module.col.m5, .row .module.col.l5
{
  width: 39.66666666667%;
  margin-right: 1%;
  margin-left: 1%;
}

.row .module.col.s6, .row .module.col.m6, .row .module.col.l6
{
  width: 48%;
  margin-right: 1%;
  margin-left: 1%;
}

.row .module.col.s7, .row .module.col.m7, .row .module.col.l7
{
  width: 56.33333333333%;
  margin-right: 1%;
  margin-left: 1%;
}

.row .module.col.s8, .row .module.col.m8, .row .module.col.l8
{
  width: 64.66666666667%;
  margin-right: 1%;
  margin-left: 1%;
}

.row .module.col.s9, .row .module.col.m9, .row .module.col.l9
{
  width: 73%;
  margin-right: 1%;
  margin-left: 1%;
}

.row .module.col.s10, .row .module.col.m10, .row .module.col.l10
{
  width: 83.33333333333%;
  margin-right: 1%;
  margin-left: 1%;
}

.row .module.col.s11, .row .module.col.m11, .row .module.col.l11
{
  width: 91.66666666667%;
  margin-right: 1%;
  margin-left: 1%;
}

.row .module.col.s12, .row .module.col.m12, .row .module.col.l12
{
  width: 98%;
  margin-right: 1%;
  margin-left: 1%;
}

.row .module.col.offset-s1, .row.module.col.offset-m1, .row.module.col.offset-l1
{
  margin-left: 8.33333333333%;
}

.row .module.col.offset-s2, .row.module.col.offset-m2, .row.module.col.offset-l2
{
  margin-left: 16.6666666667%;
}

.row .module.col.offset-s3, .row.module.col.offset-m3, .row.module.col.offset-l3
{
  margin-left: 25%;
}

.row .module.col.offset-s4, .row.module.col.offset-m4, .row.module.col.offset-l4
{
  margin-left: 33.3333333333%;
}

.row .module.col.offset-s5, .row.module.col.offset-m5, .row.module.col.offset-l5
{
  margin-left: 41.6666666667%;
}

.row .module.col.offset-s6, .row.module.col.offset-m6, .row.module.col.offset-l6
{
  margin-left: 50%;
}

.row .module.col.offset-s7, .row.module.col.offset-m7, .row.module.col.offset-l7
{
  margin-left: 58.3333333333%;
}

.row .module.col.offset-s8, .row.module.col.offset-m8, .row.module.col.offset-l8
{
  margin-left: 66.6666666667%;
}

.row .module.col.offset-s9, .row.module.col.offset-m9, .row.module.col.offset-l9
{
  margin-left: 75%;
}

.row .module.col.offset-s10, .row.module.col.offset-m10, .row.module.col.offset-l10
{
  margin-left: 83.3333333333%;
}

.row .module.col.offset-s11, .row.module.col.offset-m11, .row.module.col.offset-l11
{
  margin-left: 91.6666666667%;
}

.row .module.col.offset-s12, .row.module.col.offset-m12, .row.module.col.offset-l12
{
  margin-left: 100%;
}

.row .module.full
{
  width: auto;
}

.form-langs
{
  font-size: 2em;
  margin-right: 20px;
}

.form-langs img
{
  width: 40px;
}

.form-langs-checkbox
{
  margin-left: 10px;
}

.translation-flags
{
  list-style-type: none;
}

.translation-flags li
{
  float: left;
  border: 1px solid #D9D9D9;
  padding: 7px 7px 2px 7px;
  margin-left: -1px;
  cursor: pointer;
}

.translation-flags li.selected
{
  background-color: #E6E6E6;
}

.translation-flags li img
{
  width: 20px;
}

/* purgecss end ignore */
/*	purgecss start ignore
*/

.icon-picker {
  border: 1px solid black;
  position: relative;
  margin-top: 10px;
  /*	margin-top: -100px;
	margin-left: 100px;
*/	width: 400px;
  background-color: white;
  z-index: 1000;
}

.icons-table {
  text-align: center;
}

.icons-table td {
  padding: 10px;
  cursor: pointer;
}

.icons-table .blank {
  background-color: #273044;
}

.icons-table img {
  width: 50px;
}

.icons-table.mini {
  width: 50%;
}

.icons-table.mini td {
  padding: 0;
}

.icons-table.mini img {
  width: 30px;
}

/*	purgecss end ignore
*/
/* purgecss start ignore */

/* MAIN.CC */

body {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 1.5em;
}

.error {
  color: red;
}

.success {
  color: green;
}

.bigger {
  font-size: 1.8em;
}

.underline {
  text-decoration: underline;
}

.uhover {
  text-decoration: none;
}

.uhover:hover {
  text-decoration: underline;
  cursor: pointer;
}

.avatar {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding-top: 0;
}

.wrapper {
  margin-top: 30px;
  margin-left: 35px;
}

.wrapper-content > p {
  text-align: left;
}

.wrapper-content > .center {
  text-align: center;
  margin-left: 0;
}

.wrapper-content h1, .wrapper-content h2, .wrapper-content h3, .wrapper-content h4, .wrapper-content h5, .wrapper-content h6 {
  margin-top: 0;
}

.alink {
  color: #4242dd;
}

.alink:hover {
  text-decoration: underline;
  cursor: pointer;
}

.link {
  cursor: pointer;
}

a:hover, .link:hover {
  text-decoration: none;
}

.button-text {
  cursor: pointer;
}

.button-text span {
  margin-left: 10px;
}

/* FORMS */

label, .label {
  display: block;
  margin: 20px 0;
}

/* --- INPUT DEGUEU COMMENTÉ POUR SE LOGUER COTE DASHBOARD --- */

/* input, select {
	background-color: white;
	box-shadow: #424242;
	border: 1px solid #acacac;
	margin: 10px 30px;
	min-width: 200px;
	height: 25px;
} */

/* --- INPUT DEGUEU COMMENTÉ POUR SE LOGUER COTE DASHBOARD --- */

/* TABLE */

/*
table {
	width: 90%;
	border-collapse: collapse;
	margin: 20px auto;
}

table thead {
	font-weight: bold;
	text-align: center;
}

table td {
	border: 1px solid #424242;
}
*/

/* MODULE */

.row .module a {
  color: #4242DD;
  text-decoration: none;
}

.row .module a:hover {
  text-decoration: underline;
}

.row .module a.btn {
  color: white;
  text-decoration: none;
}

.row .module img {
  max-width: 100%;
}

.row .module .icon {
  max-width: 100px;
}

.row .module .icon.blank {
  background-color: #273044;
}

.row .module .small {
  width: 50px;
}

.row .module .module-form {
  width: 100%;
  margin: auto;
  margin-top: 20px;
}

.row .module .module-form .prev {
  width: 400px;
}

.row .module .module-form .info {
  font-size: 0.9em;
  color: #929292;
}

.row .module .module-form .title-group {
  color: #AFAFAF;
  padding: 0;
  margin: 0 0 0 15px;
  font-size: 1.2em;
}

.row .module .module-form .form-group {
  clear: both;
  width: 100%;
  border: 1px solid #DADADA;
  border-radius: 10px;
  padding: 20px 40px;
  margin: 0 0 50px 0;
}

.row .module .module-form label {
  clear: both;
  margin: 0;
  padding: 0;
  color: black;
}

.row .module .module-form .short-label {
  width: 50%;
}

.row .module .module-form .short-label input[type="text"] {
  height: 1.8em;
  width: auto;
}

.row .module .module-form input, .row .module .module-form select {
  min-width: 0;
}

.row .module .module-form p.column-5,
.row .module .module-form p.column-10,
.row .module .module-form p.column-20,
.row .module .module-form p.column-30,
.row .module .module-form p.column-40,
.row .module .module-form p.column-50,
.row .module .module-form p.column-60,
.row .module .module-form p.column-70,
.row .module .module-form p.column-80,
.row .module .module-form p.column-90 {
  font-size: 1.3em;
}

.row .module .module-form .column-5,
.row .module .module-form .column-10,
.row .module .module-form .column-20,
.row .module .module-form .column-30,
.row .module .module-form .column-40,
.row .module .module-form .column-50,
.row .module .module-form .column-60,
.row .module .module-form .column-70,
.row .module .module-form .column-80,
.row .module .module-form .column-90 {
  float: left;
  padding: 0;
}

.row .module .module-form .column-5.right60,
.row .module .module-form .column-10.right60,
.row .module .module-form .column-20.right60,
.row .module .module-form .column-30.right60,
.row .module .module-form .column-40.right60,
.row .module .module-form .column-50.right60,
.row .module .module-form .column-60.right60,
.row .module .module-form .column-70.right60,
.row .module .module-form .column-80.right60,
.row .module .module-form .column-90.right60 {
  margin-right: 60px;
}

.row .module .module-form .column-5 {
  width: 5%;
}

.row .module .module-form .column-10 {
  width: 10%;
}

.row .module .module-form .column-20 {
  width: 20%;
}

.row .module .module-form .column-30 {
  width: 30%;
}

.row .module .module-form .column-40 {
  width: 40%;
}

.row .module .module-form .column-50 {
  width: 50%;
}

.row .module .module-form .column-60 {
  width: 60%;
}

.row .module .module-form .column-70 {
  width: 70%;
}

.row .module .module-form .column-80 {
  width: 80%;
}

.row .module .module-form .column-90 {
  width: 90%;
}

.row .module .module-form .sep {
  float: left;
  padding: 0 10px;
  height: 3rem;
  line-height: 3rem;
  margin: 1px 0 8px 10px;
  border-bottom: 1px solid #9E9E9E;
  font-size: 16px;
}

.row .module .module-form .sep p {
  margin: 0;
  padding: 0;
}

.row .module .module-form input[type="text"] {
  padding: 0 10px;
}

.row .module .module-form input[type="file"] {
  border: none;
}

.row .module .module-form fieldset {
  margin-top: 20px;
  border: 1px solid black;
  padding: 10px 45px 10px 25px;
}

.row .module .module-form fieldset legend {
  text-align: center;
  font-weight: bold;
  font-size: 1.4em;
}

.wrapper-content .module {
  background-color: white;
  margin-top: 40px;
  width: 100%;
  border-radius: 4px;
  text-align: left;
  padding: 30px 40px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.wrapper-content .module.transparent {
  background-color: none;
  box-shadow: none;
}

.module .btn-small.margin-left {
  margin-left: 10px;
}

.module .object {
  float: left;
  width: 30%;
  margin-top: 50px;
  margin-right: 3%;
  min-height: 100px;
  margin: auto;
  background-color: #F2F2F2;
  border-radius: 10px;
  margin: 20px 1.5%;
  box-shadow: 0px 3px 6px #00000029;
}

.module .object.full-width {
  float: none;
  width: 100%;
  margin-right: 0;
}

.module .object .pastille {
  border-radius: 100%;
  width: 60px;
  height: 60px;
  display: block;
  float: left;
  background-color: #888;
}

.module .object .pastille img {
  width: 80%;
  margin: 4px auto;
  display: block;
}

.module .object .title {
  padding: 50px 0;
  font-size: 1.3em;
  margin-left: 5%;
  width: 45%;
  float: left;
}

.module .object .title .icon {
  background: #273044;
  float: left;
  width: 60px;
  height: 60px;
}

.module .object .title .icon.fill {
  background: none;
}

.module .object .title .icon img {
  width: 50px;
  height: 50px;
  margin-left: 5px;
  margin-top: 5px;
}

.module .object .title p {
  padding: 0;
  margin: 0;
  margin-left: 80px;
  margin-top: 10px;
}

.module .object .title p:hover {
  text-decoration: underline;
  cursor: pointer;
}

.module .object .buttons {
  padding: 50px 0;
  width: 45%;
  margin-right: 5%;
  text-align: right;
  float: right;
}

.module .object .buttons button {
  margin: 10px;
}

.module .object .clear {
  clear: both;
}

.module .half {
  width: 50%;
  float: left;
}

.module .object-2 {
  width: 100%;
  min-height: 100px;
  margin: auto;
  background-color: #F2F2F2;
  border-radius: 10px;
  margin: 40px 0;
  padding: 20px 40px;
  font-size: 1.2em;
}

.module .object-2 .client_number {
  float: right;
  font-size: 2em;
  margin-bottom: 20px;
}

.module .object-2 .activation_code {
  float: left;
  font-size: 2em;
  margin-bottom: 20px;
}

.module .object-2 .infos {
  clear: both;
  width: 100%;
  background-color: white;
}

.module .object-2 .infos td {
  width: calc(100% / 7);
}

.module .object-2 .infos .green {
  background-color: green;
}

.module .object-2 .infos .red {
  background-color: red;
}

.module .object-2 .infos .notification {
  width: 50%;
}

.module .object-2 .subtitle {
  margin-top: 40px;
  margin-left: 80px;
  font-weight: bold;
  font-size: 1.5em;
}

/* vignettes */

.module .vignette {
  width: 17%;
  background-color: white;
  border-radius: 10px;
  height: 200px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.29);
  position: relative;
  float: left;
  margin: 20px 1.5%;
}

.module .vignette .title {
  text-align: center;
  padding-top: 60px;
  font-size: 1.2em;
  cursor: pointer;
}

.module .vignette .title a {
  color: rgba(0,0,0,0.87);
  text-decoration: none;
}

.module .vignette .title a:hover {
  text-decoration: none;
}

.module .vignette .sep {
  border-top: 2px solid #F3F3F3;
  position: absolute;
  width: 100%;
  top: 150px;
  text-align: right;
  padding-top: 5px;
}

.module .vignette .sep .more {
  margin-right: 15px;
}

.module .vignette .sep i {
  cursor: pointer;
}

.module .vignette .sep .menu {
  position: absolute;
  top: 0 !important;
  left: 80% !important;
  width: 60% !important;
  text-align: left;
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.29);
}

.module .vignette .sep .menu li {
  padding: 15px 20px 10px 20px;
  cursor: pointer;
}

.module .vignette .sep .menu li.delete {
  color: red;
}

.module .vignette .sep .menu li.sep-ul {
  border-top: 2px solid #F3F3F3;
}

.module .pages-nb {
  float: right;
}

.module .pages-nb p {
  float: left;
  color: white;
  font-size: 1.2em;
  padding: 10px;
  margin: 5px;
  width: 3em;
  cursor: pointer;
  text-align: center;
  background-color: #26A69A;
  border: 1px solid #26A69A;
}

.module .pages-nb p:hover {
  background-color: white;
  color: #26A69A;
}

.module .clients .user-checkbox span {
  font-size: 1.5em;
  color: black;
  margin-left: 0;
  float: left;
}

.module .clients .user-checkbox label span {
  margin-left: 20px;
  margin-top: 7px;
}

.module .select-buttons {
  margin-bottom: 20px;
}

.loader {
  margin: auto;
  margin-top: 40vh;
  text-align: center;
}

.loader-image {
  margin: auto;
  width: 300px;
}

.reviews td {
  text-align: center;
  min-width: 150px;
}

.reviews thead {
  font-weight: bold;
}

.notation {
  text-align: center;
}

.notation img {
  width: 15px;
  margin: 0 2px;
}

/* purgecss end ignore */
/* purgecss start ignore */

.renderPage {
  margin: auto;
  margin-top: 20px;
  width: 375px;
  border: 1px solid #ABABAB;
  font-size: 1.2em;
  font-family: Nunito, Arial, sans-serif;
  padding-bottom: 15px;
}

.renderPage ul {
  padding-left: 40px;
}

.renderPage ul li {
  list-style-type: disc;
}

.renderPage .padding {
  padding-top: 15px;
}

/* Copied from webapp : App.css */

@font-face {
  font-family: Nunito;

  src: url(/static/media/Nunito-Regular.65bb0a15.ttf);
}

@font-face {
  font-family: Nunito;

  src: url(/static/media/Nunito-Light.c41c2502.ttf);

  font-weight: lighter;
}

@font-face {
  font-family: Nunito;

  src: url(/static/media/Nunito-SemiBold.713ac08d.ttf);

  font-weight: bold;
}

@font-face {
  font-family: Nunito;

  src: url(/static/media/Nunito-ExtraLightItalic.592f55ff.ttf);

  font-style: italic;
}

/* Copied from webapp : Pages.css */

/* Pages */

/* Do not replace from webapp */

.contentWrapper {
  width: 100%;
  margin: 0;
  padding: 0;
}

.contentWrapper .link {
  width: 100%;
  border: 1px solid #f6b25f;
  border-radius: 10px;
  color: white;
  background-color: #f6b25f;
  height: 40px;
  font-size: 1.2em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.contentWrapper .fullPage {
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: -12vh;
}

.contentWrapper .page {
  background-color: white;
  border-radius: 12px;
  padding: 15px 25px 0 25px;
  width: calc(100% - 40px);
  margin: 20px;
  font-size: 0.85em;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  min-height: 80px;
}

.contentWrapper .page a {
  color: black;
  text-decoration: none;
}

.contentWrapper .page .reveal-on-tap {
  max-height: 0;
  overflow-y: hidden;
  padding-bottom: 0px;
  transition: max-height 0.8s ease, padding-bottom 0.8s ease;
}

.photoWrap {
  max-height: 30vh;
  overflow-y: hidden;
  margin-bottom: 5%;
  /* position: fixed; */
}

.photoWrap img {
  width: 100%;
}

.contentWrapper .picto {
  width: 28px;
  margin-top: 12px;
  float: left;
}

.contentWrapper .title {
  padding-left: 42px;
  font-weight: bold;
  font-size: 1.15em;
  margin-bottom: 30px;
}

.contentWrapper form {
  width: 70%;
  margin: auto;
}

.contentWrapper form .row {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 5%;
}

.contentWrapper form label {
  clear: both;
  text-transform: uppercase;
  color: #afafaf;
  font-weight: bold;
}

.contentWrapper form input[type="text"] {
  width: 50%;
  float: right;
  border: 1px solid #e4e9e9;
  height: 30px;
  border-radius: 5px;
}

.contentWrapper form select {
  width: 30%;
  height: 30px;
  border-radius: 5px;
  float: right;
  background-color: white;
  border: 1px solid #e4e9e9;
}

.contentWrapper form button {
  width: 100%;
  border: 1px solid #f6b25f;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background-color: #f6b25f;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 20%;
  padding-top: 5%;
  padding-bottom: 5%;
}

/* PAGE PAIEMENT */

/*
.contentWrapper .payform .montant {
	width: 100%;
	height: 30vh;
	background-color: #fbdfb5;
	width: 100%;
	padding-top: 10vh;
	text-align: center;
}

.contentWrapper .payform .montant p {
	font-size: 1.5em;
}

.contentWrapper .payform .montant .value {
	margin: 0;
	margin-top: -5%;
	font-size: 4em;
}

.contentWrapper .payform input[type=radio] {
	background-color: white;
	color: white;
}

.contentWrapper .payform .paiement {
	margin-top: 10%;
	float: left;
	width: 25%;
	margin-left: 7%;
}

.contentWrapper .payform .paiement .creditCard {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
}

.contentWrapper .payform .paiement .creditCard input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.contentWrapper .payform .paiement .creditCard .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 14px;
	width: 14px;
	margin-top: 4%;
	background-color: white;
	border: 1px solid #f29100;
	border-radius: 50%;
}

.contentWrapper .payform .paiement .creditCard .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.contentWrapper .payform .paiement .creditCard input:checked ~ .checkmark:after {
	display: block;
}

.contentWrapper .payform .paiement .creditCard .checkmark:after {
	top: 4px;
	left: 4px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: #f29100;
}

.contentWrapper .payform .paiement .creditCard img {
	width: 100%;
}

.contentWrapper .payform .row.date {
	float: left;
	width: 60%;
	text-align: left;
}

.contentWrapper .payform .row.ccv {
	float: left;
	text-align: right;
	width: 40%;
}

.contentWrapper .payform .row label {
	clear: both;
	margin: 0;
	padding: 0;
	color: #afafaf;
	font-weight: bold;
	font-size: 0.8em;
}

.contentWrapper .payform .row input[type="text"] {
	width: 100%;
	float: right;
	border: 1px solid #e4e9e9;
	height: 30px;
	border-radius: 5px;
}

.contentWrapper .payform .row.ccv input[type="text"] {
	width: 50%;
}

.contentWrapper .payform .row select {
	width: 30%;
	height: 30px;
	border-radius: 5px;
	float: left;
	background-color: white;
	border: 1px solid #e4e9e9;
}

.contentWrapper .payform button {
	width: 100%;
	border: 1px solid #f6b25f;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	background-color: #f6b25f;
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	padding-top: 5%;
	padding-bottom: 5%;
	margin: 0;
	margin-top: 5%;
}
*/

/* END PAGE PAIGEMENT */

/* LISTS */

.contentWrapper .check {
  padding-top: 20vh;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2em;
}

.contentWrapper .check img {
  width: 50%;
  padding-bottom: 15%;
}

.content {
  width: 90%;
  min-height: 105px;
  margin: 20px auto;
  color: white;
  border-radius: 10px;
  background-color: #c6c6c6;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.content img {
  width: 15%;
  float: left;
  margin-left: 10%;
  margin-top: 7%;
}

.content p {
  margin-left: 35%;
  font-size: 1.05em;
  font-weight: bold;
  text-transform: uppercase;
  padding: 20px 10px 20px 0;
}

.contentWrapper a {
  text-decoration: none;
}

.content.first {
  background-color: #fbdfb6;
}

.content.second {
  background-color: #f9c981;
}

.content.third {
  background-color: #f6b95c;
}

.content.fourth {
  background-color: #f5a938;
}

.content.fifth {
  background-color: #f3960c;
}

/* purgecss end ignore */
/* purgecss start ignore */

.weekdays {
  font-size: 2em;
  margin-right: 20px;
}

.title-date {
  text-align: center;
  font-size: 1.5em;
}

.title-date .prev {
  font-weight: bold;
  margin-right: 30px;
  cursor: pointer;
}

.title-date .next {
  font-weight: bold;
  margin-left: 30px;
  cursor: pointer;
}

.calendar thead tr td {
  border: none;
  font-weight: bold;
  color: #AFAFAF;
  padding: 0;
  font-size: 1.3em;
}

.calendar tbody td {
  width: 14.2857142857%;
  height: 120px;
  vertical-align: top;
  padding: 5px;
  border: 1px solid #F2F2F2;
}

.calendar tbody td .date {
  font-weight: bold;
}

.calendar tbody td .animation {
  border-radius: 6px;
  background-color: #888;
  font-weight: bold;
  color: white;
  padding: 5px 10px;
  font-size: 0.8em;
}

.calendar tbody td .delete:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* purgecss end ignore */
/* purgecss start ignore */

/* .booking thead td {
	text-align: center;
	font-size: large;
} */

.booking-wrapper {
  max-height: 700px;
  overflow-y: scroll;
}

.booking .hours {
  width: 10%;
  float: left;
  border-right: 1px solid #E0E0E0;
  font-weight: bold;
  border-bottom: 1px solid #E0E0E0;
}

.booking .workers-cols {
  float: left;
  width: 90%;
  border-bottom: 1px solid #E0E0E0;
}

.booking .booking-row {
  clear: both;
}

.booking .booking-hours {
  width: 100%;
  float: left;
  height: 180px;
  padding-top: 80px;
  border-top: 1px solid #E0E0E0;
}

.booking .booking-col {
  height: 30px;
  float: left;
  border-top: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
}

.booking .booking-col.hover {
  background-color: #EBF5FD;
  color: #47A0FD;
  padding-top: 4px;
  padding-left: 5px;
}

.booking .booking-col .reservation {
  line-height: 1.5em;
  color: black;
  float: left;
  margin-left: 4px;
  position: relative;
  background-color: #A5DFF8;
  border-radius: 5px;
  margin-top: -15px;
  padding: 0 15px;
  z-index: 20;
}

.booking .booking-col .reservation p {
  position: relative;
}

.booking-header {
  width: 100%;
}

.booking-header .first {
  margin-left: 10%;
}

.booking-header .worker-name {
  text-align: center;
  font-size: 1.3em;
  float: left;
}

.booking-date {
  text-align: center;
  font-size: 1.5em;
}

.booking-date .prev {
  float: left;
  margin-left: 30%;
  font-weight: bold;
  cursor: pointer;
}

.booking-date .next {
  float: right;
  margin-right: 30%;
  font-weight: bold;
  cursor: pointer;
}

.booking-modal .benefit-title {
  font-size: 1.2em;
  font-weight: bold;
}

.booking-modal .reservation {
  background-color: white;
  border-radius: 5px;
  padding: 5px 15px;
  padding-bottom: 25px;
  width: 45%;
  float: left;
  margin: 0 2%;
  margin-bottom: 20px;
}

/* purgecss end ignore */
/* purgecss start ignore */

.row .module .top-five {
  font-size: 1.4em;
  line-height: 2em;
}

.row .module .overview {
  color: #888;
  font-size: 1.3em;
  clear: both;
}

.row .module .overview a {
  color: #888;
}

.row .module .overview .text {
  float: left;
  margin-left: 25px;
  margin-top: 2px;
  margin-bottom: 15px;
}

.row .module .overview .icon {
  float: left;
  width: 30px;
}

/* purgecss end ignore */
/* purgecss start ignore */

.color-preview {
  float: right;
  margin-right: 30%;
  width: 40px;
  height: 40px;
  border: 1px solid black;
}

/* purgecss end ignore */

